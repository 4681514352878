import MuiLink from "@mui/material/Link";
import type { ForwardedRef, ReactNode } from "react";
import { forwardRef } from "react";
import NextLink from "next/link";

interface LinkProps {
  children: ReactNode;
  href: string;
  title?: string;
}

export const Link = forwardRef(function Link(
  props: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const { children, title, href } = props;

  return (
    <NextLink href={href} legacyBehavior passHref>
      <MuiLink title={title} ref={ref}>
        {children}
      </MuiLink>
    </NextLink>
  );
});
