import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import type { Account } from "../../api";
import { AccountRankRenderer } from "../AccountRankRenderer";
import { Bfp } from "../Bfp";
import { Gold } from "../Gold";
import classes from "./AccountLinkTooltip.module.css";

interface AccountLinkTooltipProps {
  account: Account;
}

export const AccountLinkTooltip = (props: AccountLinkTooltipProps) => {
  const { account } = props;
  const { t } = useTranslation();

  return (
    <table>
      <tbody>
        <tr>
          <th>
            <Typography className={classes.th}>
              {t("AccountLinkTooltip.rank") + ": "}
            </Typography>
          </th>
          <td>
            <Typography>
              <AccountRankRenderer value={account.rank} />
            </Typography>
          </td>
        </tr>
        <tr>
          {account.banned && (
            <>
              {account.spreadBanned ? (
                <th>
                  <Typography color="error">
                    {t("AccountLinkTooltip.spreadBanned") + ": "}
                  </Typography>
                </th>
              ) : (
                <th>
                  <Typography color="error">
                    {t("AccountLinkTooltip.banned") + ": "}
                  </Typography>
                </th>
              )}
              <td>
                <Typography color="error" className={classes.banReason}>
                  {account.banReason}
                </Typography>
              </td>
            </>
          )}
        </tr>
        <tr>
          <th>
            <Typography className={classes.th}>
              {t("AccountLinkTooltip.experience") + ": "}
            </Typography>
          </th>
          <td>
            <Typography>
              {t("General.number", { value: account.experience })}
            </Typography>
          </td>
        </tr>
        <tr>
          <th>
            <Typography className={classes.th}>
              {t("AccountLinkTooltip.pvpElo") + ": "}
            </Typography>
          </th>
          <td>
            <Typography>
              {t("General.number", { value: account.pvpElo })}
            </Typography>
          </td>
        </tr>
        <tr>
          <th>
            <Typography className={classes.th}>
              {t("AccountLinkTooltip.bfp") + ": "}
            </Typography>
          </th>
          <td>
            <Typography>
              <Bfp value={account.bfp} />
            </Typography>
          </td>
        </tr>
        <tr>
          <th>
            <Typography className={classes.th}>
              {t("AccountLinkTooltip.gold") + ": "}
            </Typography>
          </th>
          <td>
            <Typography>
              <Gold value={account.gold} />
            </Typography>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
